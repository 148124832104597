import React, { useState, useCallback } from "react";
import Context from "./index";
import { getPreferredColorScheme } from "../utils";

interface IProps {
  children: React.ReactChild;
}

const ContextProvider = ({ children }: IProps) => {
  // MODAL
  const [modalElement, _setModalElement] = useState<React.ReactNode>(null);
  const [isWithCloseButton, setIsWithCloseButton] = useState<boolean>(true);

  const [actionBarProps, _setActionBarProps] = useState<ActionBar.Props>({});

  const setModalElement = useCallback(
    (value: React.ReactNode, withCloseButton: boolean = true) => {
      _setModalElement(value);
      setIsWithCloseButton(withCloseButton);
    },
    [],
  );

  const setActionBarProps = useCallback(
    (props: ActionBar.Props, merge?: boolean) =>
      _setActionBarProps((oldProps) => ({
        ...(merge ? oldProps : {}),
        ...props,
      })),
    [],
  );

  // GLOBAL PRELOADER
  const [isGlobalPreloaderShown, _setIsGlobalPreloaderShown] = useState(false);

  const setIsGlobalPreloaderShown = useCallback((value: boolean) => {
    _setIsGlobalPreloaderShown(value);
  }, []);

  // DARK THEME
  const [isDarkTheme, _setIsDarkTheme] = useState(() => {
    let savedIsDarkTheme = localStorage.getItem("Telescope.isDarkTheme");
    if (savedIsDarkTheme) savedIsDarkTheme = JSON.parse(savedIsDarkTheme);
    if (typeof savedIsDarkTheme !== "boolean") savedIsDarkTheme = null;

    return savedIsDarkTheme ?? getPreferredColorScheme() ?? false;
  });

  const setIsDarkTheme = useCallback((value: boolean) => {
    _setIsDarkTheme(value);
    localStorage.setItem("Telescope.isDarkTheme", JSON.stringify(value));
  }, []);

  // Sidebar open in mobile view;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  // Presentation
  const [isPresentation, setIsPresentation] = useState<boolean>(false);

  const [
    keywordToolLastUpdateDate,
    setKeywordToolLastUpdateDate,
  ] = useState<string>("");

  return (
    <Context.Provider
      value={{
        modalElement,
        setModalElement,
        isWithCloseButton,
        isGlobalPreloaderShown,
        setIsGlobalPreloaderShown,
        isDarkTheme,
        setIsDarkTheme,
        isSidebarOpen,
        setIsSidebarOpen,
        isPresentation,
        setIsPresentation,
        actionBarProps,
        setActionBarProps,
        keywordToolLastUpdateDate,
        setKeywordToolLastUpdateDate,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
