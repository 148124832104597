import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import truncate from "lodash/truncate";

import { replaceSpacesWith } from "../utils";

const PROJECT_NAME = "MyTelescope" as const;

type Props = {
  searchId?: string;
  widgetId?: string;
  passedName?: string;
  currentBrandName?: string;
};

export const useNameForDownloadFiles = ({
  searchId = "",
  widgetId = "",
  passedName = "",
  currentBrandName,
}: Props): string => {
  const [name, setName] = useState<string>("");

  const { t } = useTranslation();

  const searchName: string = useSelector(
    ({ searches }: Store.State) =>
      searches.find(({ id }) => id === searchId)?.name || "",
  );

  const widgetsData = useSelector(({ widgetsData }: RootState) => widgetsData);

  const widgetName: string = useMemo(() => {
    if (!widgetId) return "";

    const { placeHolderOnUi: widgetName = "" } =
      widgetsData.entities[widgetId] || {};

    return t(widgetName);
  }, [widgetsData, widgetId, t]);

  const entityName: string = useMemo(() => {
    switch (true) {
      case !!widgetName:
        return widgetName;
      case !!passedName:
        return passedName;
      default:
        return "";
    }
  }, [passedName, widgetName]);

  useEffect(() => {
    const date = format(new Date().getTime(), "MM-dd-yyyy_HH-mm-ss");

    const _currentBrandName = currentBrandName
      ? prepareStringForFileName(currentBrandName)
      : "";
    const _searchName = searchName ? prepareStringForFileName(searchName) : "";
    const _entityName = entityName ? prepareStringForFileName(entityName) : "";
    const _date = prepareStringForFileName(date);

    const fileName = `${PROJECT_NAME}${_searchName}${_currentBrandName}${_entityName}${_date}`;

    setName(sanitizeFileName(fileName));
  }, [currentBrandName, searchName, entityName]);

  return name;
};

function prepareStringForFileName(string: string) {
  return ` ${replaceSpacesWith(string, "-")}`;
}

function sanitizeFileName(input: string) {
  const notAllowedCharsReplacement = "-";
  const spaceReplacement = "_";
  const maxChars = 255;

  const sanitized = input
    .trim()
    .replace(/\s+/g, spaceReplacement)
    .replace(/[\/\?<>\\:\*\|":]/g, notAllowedCharsReplacement) // illegalRe
    .replace(/[\x00-\x1f\x80-\x9f]/g, notAllowedCharsReplacement) // controlRe
    .replace(/^\.+$/, notAllowedCharsReplacement) // reservedRe
    .replace(
      /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i,
      notAllowedCharsReplacement,
    ); // windowsReservedRe

  return truncate(sanitized, { length: maxChars });
}
