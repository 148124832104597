import { useContext, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { string as yupString } from "yup";
import { useTranslation, Trans } from "react-i18next";
import cx from "classnames";

import styles from "./Signup.module.scss";
import context from "../../../context";
import { readCompany, removeCompanyData, signUp } from "../../../store/actions";
import { Input, Select, PasswordInput } from "../../../components";
import { useTemporaryErrors } from "../../../hooks";
import { WebsiteLogoVertical } from "../../../icons";
import { withError } from "../../../hocs";
import { sendDataToGTM } from "../../../utils";
import { fetchAllMarkets } from "../../../store/markets/marketsSlice";
import { setTrackedMarkets } from "../../../store/markets/marketsApi";
import { allCountries } from "../../../data/allCountries";
import type { PostUserPayload } from "../../../store/user/api";
import { showToastNotification } from "../../../components/ToastNotification/utils";

const InputWithError = withError(Input);
const SelectWithError = withError(Select);

const emailSchema = yupString().email();

const { REACT_APP_ENV = "prod", REACT_APP_PROJECT } = process.env;

const TERMS_AND_CONDITIONS_LINK =
  "https://www.mytelescope.io/terms-and-conditions";
const PRIVACY_POLICY_LINK = "https://www.mytelescope.io/privacy-policy";

const Signup = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { sharedDashboardId?: string };
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const markets = useSelector(({ markets }: RootState) => markets);

  const companyId = useMemo(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const companyId = params.get("company");
    if (companyId) return companyId;
  }, [location.search]);

  const { setIsGlobalPreloaderShown, isGlobalPreloaderShown } = useContext(
    context,
  );

  const initialCompanyName = useSelector(
    ({ company }: Store.State) => company.name,
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [marketCode, setMarketCode] = useState("");
  // const [workFor, setWorkFor] = useState("");
  // const [primaryFocus, setPrimaryFocus] = useState<Option[]>([]);

  const [newPasswordErrors, setNewPasswordErrors] = useState<{
    [field: string]: string;
  }>({});

  const { errors, setErrors } = useTemporaryErrors(3000);

  const isMarketsLoaded = markets.status === "succeeded";
  const isSelectedCountryIncludesInMarkets = !!markets.entities[marketCode];

  const selectedCountryName = useMemo(() => {
    return allCountries.find(({ value }) => value === marketCode)?.label || "";
  }, [marketCode]);

  useEffect(() => {
    sendDataToGTM("UserWatchSignUpPage");
  }, []);

  useEffect(() => {
    if (!companyId) return;

    (async () => {
      setIsGlobalPreloaderShown(true);
      try {
        await Promise.all([
          companyId && dispatch(readCompany(companyId)),
          dispatch(fetchAllMarkets()),
        ]);
      } catch (error) {
        showToastNotification({ type: "error", text: t("request_error") });
      }
      setIsGlobalPreloaderShown(false);
    })();

    return () => {
      dispatch(removeCompanyData());
    };
  }, [companyId, setIsGlobalPreloaderShown, dispatch, t]);

  useEffect(() => {
    if (isMarketsLoaded || companyId) return;

    (async () => {
      setIsGlobalPreloaderShown(true);

      try {
        await dispatch(fetchAllMarkets());
      } catch (error) {
        showToastNotification({ type: "error", text: t("request_error") });
      }
      setIsGlobalPreloaderShown(false);
    })();
  }, [isMarketsLoaded, companyId, setIsGlobalPreloaderShown, dispatch, t]);

  const newPasswordChangeHandler = (
    value: string,
    requirementsErrors?: Errors,
  ) => {
    setPassword1(value);
    requirementsErrors && setNewPasswordErrors(requirementsErrors);
  };

  function validate(): Errors {
    const validationErrors: typeof errors = {};

    if (!firstName.trim().length)
      validationErrors.firstName = t("registration_error_required");

    if (!lastName.trim().length)
      validationErrors.lastName = t("registration_error_required");

    if (!emailSchema.isValidSync(email))
      validationErrors.email = t("registration_error_not_valid_mail");
    if (!email.trim().length)
      validationErrors.email = t("registration_error_required");

    if (!!Object.entries(newPasswordErrors).length) {
      validationErrors.password1 = t(
        "profile_p_new_password_error_not_correct",
      );
    }

    if (!password1.trim().length) {
      validationErrors.password1 = t("registration_error_required");
    }

    if (password1 !== password2)
      validationErrors.password2 = t(
        "registration_error_password_do_not_match",
      );

    if (!password2.trim().length)
      validationErrors.password2 = t("registration_error_required");

    // This fields validate only if user is registering NOT through company link
    if (!companyId) {
      if (!companyName.trim().length)
        validationErrors.companyName = t("registration_error_required");

      if (!marketCode.trim().length)
        validationErrors.marketCode = t("registration_error_required");
    }

    return validationErrors;
  }

  async function handleSubmitForm() {
    const sharedDashboardId = getSharedDashboardId();

    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setIsGlobalPreloaderShown(true);
    try {
      const payload: PostUserPayload = {
        email: email.trim(),
        password: password1.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        companyId,
        companyName,
        marketCode,
        whiteLabelProject: REACT_APP_PROJECT,
        env: REACT_APP_ENV,
      };

      const isUserSignUpThroughCompanyLink = typeof companyId === "string";

      const signUpData = (await dispatch(
        signUp(payload, isUserSignUpThroughCompanyLink),
      )) as {
        userId?: string;
      };

      if (sharedDashboardId) {
        sendDataToGTM("UserSignedUpAfterPresentationReview", {
          sharedDashboardId,
        });
      }

      await writeCountry(marketCode);

      if (signUpData.userId) {
        history.push(`/verify/${signUpData.userId}`);
      }
    } catch (error) {
      const gotPossibleErrorMessage =
        error?.response?.data?.details?.[0]?.message ||
        error.response?.data?.error ||
        error.response?.data;

      const errorMessage =
        gotPossibleErrorMessage && typeof gotPossibleErrorMessage === "string"
          ? gotPossibleErrorMessage
          : "Oops, something went wrong";

      showToastNotification({ type: "error", text: errorMessage });
    }
    setIsGlobalPreloaderShown(false);
  }

  function getNote1Text() {
    if (companyId) {
      return !isGlobalPreloaderShown
        ? t("tip_with_brand", {
            companyName: `"${initialCompanyName}"`,
          })
        : null;
    }
    return t("tip_without_brand");
  }

  async function writeCountry(selectedMarketId: string) {
    const isSelectedMarketInMarketList = markets.ids.some(
      (id) => id === selectedMarketId,
    );

    const market = getMarketData(
      isSelectedMarketInMarketList,
      selectedMarketId,
    );

    if (market) {
      await setTrackedMarkets(market);
    }
  }

  function getMarketData(
    isSelectedMarketInMarketList: boolean,
    marketId: string,
  ) {
    if (isSelectedMarketInMarketList) {
      const foundMarket = Object.entries(markets.entities).find(
        ([id]) => id === marketId,
      );

      const foundMarketData = foundMarket?.[1];

      const { code, label } = foundMarketData || {};
      if (code && label) {
        return {
          code,
          label,
          isCountryInMW: isSelectedMarketInMarketList,
        };
      }
    }

    const { value, label } =
      allCountries.find(({ value }) => value === marketId) || {};

    if (value && label) {
      return {
        code: value,
        label: label,
        isCountryInMW: isSelectedMarketInMarketList,
      };
    }
  }

  function getSharedDashboardId() {
    let sharedDashboardId: string = "";

    try {
      sharedDashboardId = state?.sharedDashboardId || "";
    } catch (err) {
      console.error(err);
    }

    return sharedDashboardId;
  }

  return (
    <div className={styles.signup}>
      <div className={styles.logoWrapper}>
        <WebsiteLogoVertical className={styles.logo} />
      </div>
      <div className={styles.note1}>{getNote1Text()}</div>
      <form
        name="signUp"
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitForm();
        }}
      >
        <div className={styles.inputsWrapper}>
          <InputWithError
            className={styles.input}
            value={firstName}
            changeHandler={setFirstName}
            placeholder={t("registration_first_name")}
            error={errors.firstName}
            maxLength={50}
          />
          <InputWithError
            className={styles.input}
            value={lastName}
            changeHandler={setLastName}
            placeholder={t("registration_last_name")}
            error={errors.lastName}
            maxLength={50}
          />
          <InputWithError
            className={cx(styles.input, styles.email)}
            value={email}
            changeHandler={setEmail}
            placeholder={t("registration_email")}
            error={errors.email}
          />
          <PasswordInput
            className={styles.input}
            value={password1}
            onChangeHandler={newPasswordChangeHandler}
            isPasswordRequirementsShow
            error={errors.password1}
            placeholder={t("registration_password")}
          />
          <PasswordInput
            className={styles.input}
            value={password2}
            onChangeHandler={setPassword2}
            error={errors.password2}
            placeholder={t("registration_rewrite_password")}
          />
          {!companyId && (
            <>
              <InputWithError
                className={styles.input}
                value={companyName}
                maxLength={120}
                changeHandler={setCompanyName}
                placeholder={t("registration_company_name")}
                error={errors.companyName}
                isEnabled={!companyId}
              />
              <SelectWithError
                className={styles.input}
                options={allCountries}
                value={marketCode}
                changeHandler={setMarketCode}
                error={errors.marketCode}
                isEnabled={!companyId}
                placeholder={t("registration_country")}
              />
            </>
          )}
        </div>

        <button className={`${styles.button} ${styles.buttonGreen}`}>
          {t("registration_sign_up")}
        </button>
      </form>
      {marketCode && !isSelectedCountryIncludesInMarkets && (
        <div className={cx(styles.note2, styles.selectedCountry)}>
          <Trans
            i18nKey="registration_country_warning"
            values={{
              selectedCountryName,
            }}
          />
        </div>
      )}
      <div className={styles.note2}>
        <Trans
          i18nKey="terms_and_conditions_tip"
          components={[
            <a
              href={TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noreferrer"
            />,
            <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer" />,
          ]}
        />
      </div>
      <button
        className={`${styles.button} ${styles.buttonBlue}`}
        onClick={() => history.push("/login")}
      >
        {t("already_have_account")}
      </button>
    </div>
  );
};

export default Signup;
