import { useContext, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./PasswordReset.module.scss";
import context from "../../../context";
import { resetPassword } from "../../../store/actions";
import { Input } from "../../../components";
import { useTemporaryErrors } from "../../../hooks";
import { WebsiteLogoVertical } from "../../../icons";
import { withError } from "../../../hocs";
import { showToastNotification } from "../../../components/ToastNotification/utils";

const InputWithError = withError(Input);

interface Props {
  resetPassword: typeof resetPassword;
}

const PasswordReset = ({ resetPassword }: Props) => {
  const { t } = useTranslation();
  const { setIsGlobalPreloaderShown } = useContext(context);

  const [isLinkSent, setIsLinkSent] = useState(false);
  const [email, setEmail] = useState("");

  const { errors, setErrors } = useTemporaryErrors(3000);

  function validate() {
    const validationErrors: typeof errors = {};
    if (!email.trim().length)
      validationErrors.email = t("forgot_password_email_error");
    return validationErrors;
  }

  async function handleSubmitForm() {
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    setIsGlobalPreloaderShown(true);
    try {
      await resetPassword(email);
      setIsLinkSent(true);
    } catch (error) {
      showToastNotification({
        type: "error",
        text: error || t("request_error"),
      });
    }
    setIsGlobalPreloaderShown(false);
  }

  return (
    <div className={styles.passwordReset}>
      <WebsiteLogoVertical className={styles.logo} />
      {!isLinkSent ? (
        <>
          <div className={styles.title}>{t("forgot_password_title")}</div>
          <form
            name="resetPassword"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitForm();
            }}
          >
            <InputWithError
              className={styles.input}
              type="email"
              value={email}
              changeHandler={(value) => setEmail(value)}
              placeholder={t("forgot_password_email")}
              error={errors.email}
            />
            <button className={styles.button}>
              {t("forgot_password_button")}
            </button>
          </form>
        </>
      ) : (
        <>
          <div className={styles.title}>{t("check_your_email_title")}</div>
          <div className={styles.note}>{t("check_your_email_note")}</div>
        </>
      )}
      <Link className={styles.link} to="/login">
        {t("forgot_password_go_back")}
      </Link>
    </div>
  );
};

export default connect(null, { resetPassword })(PasswordReset);
