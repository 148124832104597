import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "./UpgradeButton.module.scss";
import { UpgradePlan } from "src/icons";
import { ROUTS } from "src/data/routs";
import { selectCompanySubscriptionPlan } from "src/store/selectors";

type Props = {
  isSidebarOpen: boolean;
};

export const UpgradeButton: FC<Props> = ({ isSidebarOpen }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const companySubscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const subscriptionPlanName: string = useMemo(() => {
    const subPlanName = companySubscriptionPlan?.name || "";

    if (!subPlanName) return "";

    return t("menu_sub_plan", {
      name: subPlanName,
    });
  }, [companySubscriptionPlan, t]);

  const onUpgradeClick = (): void => history.push(ROUTS.pricingPage);

  return (
    <div className={styles.upgradeLinkWrapper}>
      {isSidebarOpen ? (
        <div className={styles.upgradePlanSection}>
          {!!subscriptionPlanName && <span>{subscriptionPlanName}</span>}
          <button type="button" onClick={onUpgradeClick}>
            {t("menu_upgrade")}
          </button>
        </div>
      ) : (
        <div className={styles.upgradePlanButton} onClick={onUpgradeClick}>
          <UpgradePlan />
        </div>
      )}
    </div>
  );
};
