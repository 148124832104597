import { memo } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import styles from "./Preloader.module.scss";

type Props = {
  className?: string;
  type?: "scale" | "bar";
  modifier?: "global" | "inner";
};

export const Preloader = memo(
  ({ className = "", type = "scale", modifier }: Props) => {
    return (
      <div
        className={`${styles.preloader} ${
          modifier ? styles["preloader_" + modifier] : ""
        } ${className}`}
      >
        {type === "scale" && (
          <ScaleLoader color="#ef336a" width="8px" height="40px" loading />
        )}
        {type === "bar" && (
          <BarLoader color="#ef336a" width="80px" height="4px" loading />
        )}
      </div>
    );
  },
);
